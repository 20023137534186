import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './Navbar'; // Sidebar

interface LayoutProps {
  onLogout: () => void;
}

const Layout: React.FC<LayoutProps> = ({ onLogout }) => {
  return (
    <div>
      {/* Fixed Sidebar */}
      <Navbar onLogout={onLogout} />

      {/* Main content area */}
      <div className="ml-48 p"> {/* Offset the main content by the sidebar width */}
        <Outlet /> {/* This renders the Courses or Workshops component */}
      </div>
    </div>
  );
};

export default Layout;
