import axios from 'axios';

const API_URL = 'https://spark-mind.vercel.app/api/job/';

export interface Job {
  _id?: string;
  title: string;
  description: string;
  qualification: string;
  active: boolean;
  createdDate?: string;
}

export const fetchApplications = async () => {
    const response = await axios.get('https://spark-mind.vercel.app/api/request/');
    return response.data;
  };

  // Fetch all jobs
export const fetchJobs = async (): Promise<Job[]> => {
  const response = await axios.get<Job[]>(API_URL);
  return response.data;
};

// Add a new job
export const addJob = async (jobData: Job): Promise<Job> => {
  const response = await axios.post<Job>(API_URL, jobData);
  return response.data;
};

// Update a job
export const updateJob = async (jobId: string, jobData: Job): Promise<Job> => {
  const response = await axios.put(`${API_URL}${jobId}`, jobData); // Remove trailing slash
  return response.data;
};


// Delete a job
export const deleteJob = async (jobId: string): Promise<void> => {
  await axios.delete(`${API_URL}${jobId}`); // Remove trailing slash
};
