import React, { useEffect, useState } from 'react';
import { fetchApplications } from '../services/jobService'; // API service

interface Application {
  _id: string;
  name: string;
  description: string;
  youtubeLink: string;
  status: string;
  createdAt: string;
  resumeLink: {
    filename: string;
    url: string;
  };
}

const Applications: React.FC = () => {
  const [applications, setApplications] = useState<Application[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    const getApplications = async () => {
      try {
        const data = await fetchApplications();
        setApplications(data);
      } catch (err) {
        setError('Failed to fetch applications');
      }
    };
    getApplications();
  }, []);

  const toggleDescription = (id: string) => {
    setExpandedDescriptions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Applications</h1>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {applications.map((application) => (
          <div key={application._id} className="bg-blue-300 text-white p-4 rounded-lg shadow-xl">
            <h2 className="text-lg ">Name : {application.name}</h2>

            {/* Description with Read More */}
            <p
              className={`mb-2 ${
                expandedDescriptions[application._id] ? '' : 'max-h-24 overflow-hidden'
              } break-words `} // Apply max height and ensure word wrapping
              style={{ wordBreak: 'break-word' }}
            >About Self :
              {expandedDescriptions[application._id]
                ? application.description
                : application.description.length > 100
                ? `${application.description.slice(0, 100)}...`
                : application.description}

              {/* Show Read More button only if description is longer than 100 characters */}
              {application.description.length > 100 && (
                <button
                  onClick={() => toggleDescription(application._id)}
                  className="text-blue-500 ml-2"
                >
                  {expandedDescriptions[application._id] ? 'Read Less' : 'Read More'}
                </button>
              )}
            </p>

          
            <p className="mt-2">Status: {application.status}</p>
            <p className="text-sm">Applied on: {new Date(application.createdAt).toLocaleDateString()}</p>
            <div className="wr flex items-center justify-between">
            <a
              href={application.resumeLink.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 mt-2 block"
            >
              View Resume
            </a>
            <a
              href={application.youtubeLink}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 mt-2"
            >
              Watch Video
            </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Applications;
