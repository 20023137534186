import React, { useState, useEffect } from 'react';
import { getWorkshops, addWorkshop, updateWorkshop, deleteWorkshop, Workshop } from '../services/workshopService';
import WorkshopForm from '../components/WorkshopForm';
import Modal from '../components/Modal'; // Assuming you have a Modal component for centering forms

const Workshops: React.FC = () => {
  const [workshops, setWorkshops] = useState<Workshop[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [editingWorkshop, setEditingWorkshop] = useState<Workshop | null>(null);

  useEffect(() => {
    fetchWorkshops();
  }, []);

  const fetchWorkshops = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getWorkshops();
      setWorkshops(data);
    } catch (err) {
      setError('Failed to fetch workshops.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddWorkshopClick = () => {
    setEditingWorkshop(null);
    setIsFormVisible(true);
  };

  const handleFormSubmit = async (formData: FormData) => {
    try {
      setLoading(true);
      if (editingWorkshop) {
        const updatedWorkshop = await updateWorkshop(editingWorkshop._id!, formData);
        setWorkshops((prevWorkshops) =>
          prevWorkshops.map((workshop) =>
            workshop._id === updatedWorkshop._id ? updatedWorkshop : workshop
          )
        );
      } else {
        const newWorkshop = await addWorkshop(formData);
        setWorkshops((prevWorkshops) => [newWorkshop, ...prevWorkshops]);
      }
      setIsFormVisible(false);
    } catch (err) {
      setError('Failed to save workshop.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteWorkshop = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this workshop?')) {
      try {
        setLoading(true);
        await deleteWorkshop(id);
        setWorkshops((prevWorkshops) => prevWorkshops.filter((workshop) => workshop._id !== id));
      } catch (err) {
        setError('Failed to delete workshop.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEditWorkshop = (workshop: Workshop) => {
    setEditingWorkshop(workshop);
    setIsFormVisible(true);
  };

  const handleCancel = () => {
    setIsFormVisible(false);
    setEditingWorkshop(null);
  };

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Workshops</h1>
        <button
          onClick={handleAddWorkshopClick}
          className="flex items-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
        >
          Add Workshop
        </button>
      </div>

      {loading && <p>Loading workshops...</p>}
      {error && <p className="text-red-500">{error}</p>}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {!loading && !error && workshops.length > 0 && workshops.map((workshop) => (
          <div key={workshop._id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <img
              src={workshop.images[0]?.url || 'path/to/default-image.jpg'}
              alt="Workshop"
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h2 className="text-xl font-bold text-gray-800 mb-2">Workshop</h2>
              <p className="text-blue-500 mb-4">
                <a href={workshop.link} target="_blank" rel="noopener noreferrer">
                  Visit Link
                </a>
              </p>
              <p className="text-gray-600 mb-4">Valid Till: {new Date(workshop.validTill).toLocaleDateString()}</p>
              <div className="flex justify-between items-center">
                <div>
                  <button
                    onClick={() => handleEditWorkshop(workshop)}
                    className="text-blue-500 hover:text-blue-700 transition"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteWorkshop(workshop._id!)}
                    className="text-red-500 hover:text-red-700 transition"
                  >
                    Delete
                  </button>
                </div>
                <div>
                  {workshop.active ? (
                    <span className="bg-green-500 text-white px-2 py-1 rounded">Active</span>
                  ) : (
                    <span className="bg-red-500 text-white px-2 py-1 rounded">Inactive</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for the Workshop Form */}
      <Modal isOpen={isFormVisible} onClose={handleCancel}>
        <WorkshopForm onSubmit={handleFormSubmit} onCancel={handleCancel} editingWorkshop={editingWorkshop} />
      </Modal>
    </div>
  );
};

export default Workshops;
