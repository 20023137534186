import React, { useState } from 'react';
import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom'; // Use HashRouter
import Navbar from './components/Navbar'; // Sidebar (Navbar)
import Courses from './pages/Courses';
import Workshops from './pages/Workshops';
import LoginPage from './pages/LoginPage';
import Layout from './components/Layout'; // Layout that includes Sidebar and content
import Applications from './components/Applications';
import PostJob from './components/PostJob';
import SaleBanner from './components/SaleBanner';
import CategoryComponent from './components/CategoryComponent';

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(() => {
    // Retrieve the login state from local storage, if available
    const storedLoginState = localStorage.getItem('isLoggedIn');
    return storedLoginState === 'true'; // Convert to boolean
  });

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true'); // Store login state in local storage
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn'); // Remove login state from local storage
  };

  return (
    <Router>
      <Routes>
        {/* Login Route */}
        <Route
          path="/login"
          element={
            isLoggedIn ? <Navigate to="/courses" /> : <LoginPage onLoginSuccess={handleLoginSuccess} />
          }
        />

        {/* Private Layout with Sidebar (Protected Route) */}
        <Route
          path="/"
          element={
            isLoggedIn ? <Layout onLogout={handleLogout} /> : <Navigate to="/login" />
          }
        >
          {/* Nested Routes inside Layout (shows sidebar + content) */}
          <Route path="courses" element={<Courses />} />
          <Route path="workshops" element={<Workshops />} />
          <Route path="applications" element={<Applications />} />
          <Route path="post-job" element={<PostJob />} />
          <Route path="saleBanner" element={<SaleBanner />} />
          <Route path="Category" element={<CategoryComponent />} />
        </Route>

        {/* Default Route */}
        <Route
          path="*"
          element={<Navigate to={isLoggedIn ? "/courses" : "/login"} />}
        />
      </Routes>
    </Router>
  );
};

export default App;
