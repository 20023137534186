import React, { useState, useEffect } from 'react';
import { fetchSubCategories, SubCategory } from '../services/subCategoryService';
import { fetchCategories, Category } from '../services/categoryService'; // Assuming you have a service to fetch categories
import { Course } from '../services/courseService';

interface CourseFormProps {
  onSubmit: (formData: FormData) => void;
  onCancel: () => void;
  editingCourse?: Course | null;
}

const CourseForm: React.FC<CourseFormProps> = ({ onSubmit, onCancel, editingCourse }) => {
  const [title, setTitle] = useState('');
  const [categories, setCategories] = useState<Category[]>([]);
  const [category, setCategory] = useState<string>(''); // Expecting a string for category ID
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>(''); // Expecting a string for subcategory ID
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState(''); // Error state for description
  const [image, setImage] = useState<File | null>(null);
  const [enrolledCount, setEnrolledCount] = useState(0);
  const [link, setLink] = useState('');
  const [price, setPrice] = useState('');
  const [languages, setLanguages] = useState<string>(''); // State for languages input
  
  const [duration, setDuration] = useState<number>(0); // State for duration in minutes
const [noOfLessons, setNoOfLessons] = useState<number>(0); // State for the number of lessons


  // Load categories on mount
  useEffect(() => {
    const loadCategories = async () => {
      const categoryList = await fetchCategories();
      setCategories(categoryList);
    };
    loadCategories();
  }, []);

  // Populate the form if we are editing a course
  useEffect(() => {
    if (editingCourse) {
      setTitle(editingCourse.title);
      setCategory(editingCourse.category._id);
      setDescription(editingCourse.description);
      setEnrolledCount(editingCourse.enrolledCount);
      setLink(editingCourse.link || '');
      setPrice(editingCourse.price || '');
      setSelectedSubCategory(editingCourse.subCategory?.[0]?._id || '');
      setLanguages(editingCourse.language?.join(', ') || '');
      setDuration(editingCourse.duration || 0); // Set initial duration if present
      setNoOfLessons(editingCourse.noOfLessions || 0); // Set initial number of lessons if present
    }
  }, [editingCourse]);
  

  // Fetch subcategories when the category changes
  useEffect(() => {
    const loadSubCategories = async () => {
      if (category) {
        const subCategoryList = await fetchSubCategories(category);
        setSubCategories(subCategoryList);
      } else {
        setSubCategories([]); // Clear subcategories if no category is selected
      }
    };
    loadSubCategories();
  }, [category]);

  

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (description.length > 500) {
      setDescriptionError('Only 500 characters are allowed.');
      return;
    }
  
    const formData = new FormData();
    formData.append('title', title);
    formData.append('category', category);
    formData.append('description', description);
    if (image) {
      formData.append('image', image);
    }
    formData.append('enrolledCount', enrolledCount.toString());
    formData.append('link', link);
    formData.append('price', price.toString());
    if (selectedSubCategory) {
      formData.append('subCategory', selectedSubCategory);
    }
    formData.append('language', languages);
    formData.append('duration', duration.toString()); // Add duration
    formData.append('noOfLessions', noOfLessons.toString()); // Add number of lessons
  
    onSubmit(formData);
  };
  
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (value.length <= 500) {
      setDescription(value);
      setDescriptionError(''); // Clear error if length is valid
    } else {
      setDescription(value);
      setDescriptionError('Only 500 characters are allowed.');
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
      <div className="absolute inset-0 bg-black opacity-50 z-40"></div>
      <div
        className="bg-gray-800 p-6 rounded-lg shadow-lg z-50 w-full max-w-lg overflow-y-auto"
        style={{ maxHeight: '90vh' }}
      >
        <h2 className="text-white text-xl mb-4">{editingCourse ? 'Update Course' : 'Add New Course'}</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-white">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-2 border border-gray-700 rounded"
              required
            />
          </div>

          {/* Category Dropdown */}
          <div className="mb-4">
            <label className="block text-white">Category</label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full p-2 border border-gray-700 rounded"
              required
            >
              <option value="">Select Category</option>
              {categories.map((cat) => (
                <option key={cat._id} value={cat._id}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>

          {/* Subcategory Dropdown */}
          {subCategories.length > 0 && (
            <div className="mb-4">
              <label className="block text-white">Subcategory</label>
              <select
                value={selectedSubCategory}
                onChange={(e) => setSelectedSubCategory(e.target.value)}
                className="w-full p-2 border border-gray-700 rounded"
              >
                <option value="">Select Subcategory</option>
                {subCategories.map((subCat) => (
                  <option key={subCat._id} value={subCat._id}>
                    {subCat.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Languages Input */}
          <div className="mb-4">
            <label className="block text-white">Languages (comma separated)</label>
            <input
              type="text"
              value={languages}
              onChange={(e) => setLanguages(e.target.value)}
              className="w-full p-2 border border-gray-700 rounded"
              placeholder="e.g., English, Spanish, French"
            />
          </div>

          <div className="mb-4">
            <label className="block text-white">Description</label>
            <textarea
              value={description}
              onChange={handleDescriptionChange}
              maxLength={500}  // Restrict the input to 500 characters
              className="w-full p-2 border border-gray-700 rounded"
              required
            />
            <p className="text-sm text-gray-400">{description.length}/500 characters</p>
            {descriptionError && (
              <p className="text-red-500 text-sm">{descriptionError}</p> // Display error message
            )}
          </div>
          
          <div className="mb-4">
            <label className="block text-white">Image</label>
            <input
              type="file"
              onChange={(e) => setImage(e.target.files?.[0] || null)}
              className="w-full p-2 border border-gray-700 rounded"
            />
          </div>
          <div className="mb-4">
  <label className="block text-white">Duration (minutes)</label>
  <input
    type="number"
    value={duration}
    onChange={(e) => setDuration(Number(e.target.value))}
    className="w-full p-2 border border-gray-700 rounded"
    required
  />
</div>
<div className="mb-4">
  <label className="block text-white">Number of Lessons</label>
  <input
    type="number"
    value={noOfLessons}
    onChange={(e) => setNoOfLessons(Number(e.target.value))}
    className="w-full p-2 border border-gray-700 rounded"
    required
  />
</div>

          {/* <div className="mb-4">
            <label className="block text-white">Enrolled Count</label>
            <input
              type="number"
              value={enrolledCount}
              onChange={(e) => setEnrolledCount(Number(e.target.value))}
              className="w-full p-2 border border-gray-700 rounded"
              required
            />
          </div> */}
          <div className="mb-4">
            <label className="block text-white">Link</label>
            <input
              type="url"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className="w-full p-2 border border-gray-700 rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-white">Price</label>
            <input
              type="text"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="w-full p-2 border border-gray-700 rounded"
              required
            />
          </div>
          

          <div className="flex justify-between">
            <button type="button" onClick={onCancel} className="bg-red-600 text-white p-2 rounded">
              Cancel
            </button>
            <button type="submit" className="bg-blue-600 text-white p-2 rounded">
              {editingCourse ? 'Update Course' : 'Add Course'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CourseForm;
