import axios from 'axios';

const API_URL = 'https://spark-mind.vercel.app/api/workshop'; // Update this URL as per your API

export interface Workshop {
  _id?: string;
  link: string;
  images: { filename: string; url: string }[];
  createdDate: string;
  active: boolean;
  validTill: string;
}

export const getWorkshops = async () => {
  const response = await axios.get<Workshop[]>(API_URL);
  return response.data;
};

export const addWorkshop = async (workshop: FormData) => {
  const response = await axios.post<Workshop>(`${API_URL}/`, workshop);
  return response.data;
};

export const updateWorkshop = async (id: string, workshop: FormData) => {
  const response = await axios.put<Workshop>(`${API_URL}/${id}`, workshop);
  return response.data;
};

export const deleteWorkshop = async (id: string) => {
  await axios.delete(`${API_URL}${id}`);
};
