import React, { useState, useEffect } from 'react';
import { getCourses, addCourse, deleteCourse, updateCourse, Course } from '../services/courseService';
import CourseForm from '../components/CourseForm';
import Modal from '../components/Modal';
import { FiUsers, FiClock, FiEdit, FiTrash2, FiBookOpen } from 'react-icons/fi';

const Courses: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isFormVisible, setIsFormVisible] = useState<boolean>(false);
  const [editingCourse, setEditingCourse] = useState<Course | null>(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getCourses();
      setCourses(data);
    } catch (err) {
      setError('Failed to fetch courses.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddCourseClick = () => {
    setEditingCourse(null);
    setIsFormVisible(true);
  };

  const handleFormSubmit = async (formData: FormData) => {
    try {
      setLoading(true);
      if (editingCourse) {
        const updatedCourse = await updateCourse(editingCourse._id, formData);
        setCourses((prevCourses) =>
          prevCourses.map((course) =>
            course._id === updatedCourse._id ? updatedCourse : course
          )
        );
      } else {
        const newCourse = await addCourse(formData);
        setCourses((prevCourses) => [newCourse, ...prevCourses]);
      }
      setIsFormVisible(false);
    } catch (err) {
      setError('Failed to save course.');
    } finally {
      setLoading(false);
    }
    fetchCourses();
  };

  const handleDeleteCourse = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this course?')) {
      try {
        setLoading(true);
        await deleteCourse(id);
        setCourses((prevCourses) => prevCourses.filter((course) => course._id !== id));
      } catch (err) {
        setError('Failed to delete course.');
      } finally {
        setLoading(false);
      }
    }
    fetchCourses();
  };

  const handleEditCourse = (course: Course) => {
    setEditingCourse(course);
    setIsFormVisible(true);
  };

  const handleCancel = () => {
    setIsFormVisible(false);
    setEditingCourse(null);
  };

  const toggleDescription = (courseId: string) => {
    setExpandedDescriptions((prevState) => ({
      ...prevState,
      [courseId]: !prevState[courseId],
    }));
  };

  return (
    <div className="container mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-gray-800">Courses</h1>
        <button
          onClick={handleAddCourseClick}
          className="flex items-center bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
        >
          Add Course
        </button>
      </div>

      {loading && <p>Loading courses...</p>}
      {error && <p className="text-red-500">{error}</p>}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {!loading && !error && courses.length > 0 && courses.map((course) => (
          <div key={course._id} className="bg-white rounded-lg shadow-md overflow-hidden">
            <img
              src={course.image?.url || 'path/to/default-image.jpg'}
              alt={course.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <h2 className="text-xl font-bold text-gray-800 mb-2">{course.title}</h2>
              <div className="flex items-center text-gray-600 text-sm mb-2">
                <FiBookOpen className="mr-2" />
                <span>{course.noOfLessions}</span>
                <FiClock className="ml-4 mr-2" />
                <span>{course.duration || 0} min</span>
              </div>

              {/* Display Category and Subcategory */}
              <div className="flex flex-col text-gray-600 text-sm mb-2">
                <span><b>Category:</b> {course.category?.name || 'Not specified'}</span>
                {course.subCategory && course.subCategory.length > 0 ? (
                  <span><b>Subcategory:</b> {course.subCategory.map(sub => sub.name).join(', ')}</span>
                ) : (
                  <span><b>Subcategory:</b> Not specified</span>
                )}
              </div>

              {/* Display Languages */}
              <div className="flex items-center text-gray-600 text-sm mb-2">
                <span><b>Languages:</b> {course.language?.join(', ') || 'Not specified'}</span>
              </div>

              {/* Description with Read More/Less functionality */}
              <p className="text-gray-600"><b>Description:</b>
                {expandedDescriptions[course._id]
                  ? course.description
                  : `${course.description.slice(0, 57)}...`}
              </p>
              <button
                onClick={() => toggleDescription(course._id)}
                className="text-blue-500"
              >
                {expandedDescriptions[course._id] ? 'Read Less' : 'Read More'}
              </button>

              {course.link && (
                <p className="text-blue-500 mb-4">
                  <a href={course.link} target="_blank" rel="noopener noreferrer">
                    Visit Link
                  </a>
                </p>
              )}

              <div className="flex justify-between items-center">
                <span className="text-lg font-semibold text-gray-800">{course.price}</span>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleEditCourse(course)}
                    className="text-blue-500 hover:text-blue-700 transition"
                  >
                    <FiEdit />
                  </button>
                  <button
                    onClick={() => handleDeleteCourse(course._id)}
                    className="text-red-500 hover:text-red-700 transition"
                  >
                    <FiTrash2 />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for the Course Form */}
      <Modal isOpen={isFormVisible} onClose={handleCancel}>
        <CourseForm onSubmit={handleFormSubmit} onCancel={handleCancel} editingCourse={editingCourse} />
      </Modal>
    </div>
  );
};

export default Courses;
