import React, { useState, useEffect } from 'react';
import { Workshop } from '../services/workshopService'; // Adjust as needed for your Workshop model

interface WorkshopFormProps {
  onSubmit: (formData: FormData) => void;
  onCancel: () => void;
  editingWorkshop?: Workshop | null;
}

const WorkshopForm: React.FC<WorkshopFormProps> = ({ onSubmit, onCancel, editingWorkshop }) => {
  const [link, setLink] = useState('');
  const [images, setImages] = useState<FileList | null>(null);
  const [validTill, setValidTill] = useState('');
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (editingWorkshop) {
      setLink(editingWorkshop.link);
      setValidTill(new Date(editingWorkshop.validTill).toISOString().slice(0, 10)); // Ensure date format
      setActive(editingWorkshop.active);
    }
  }, [editingWorkshop]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('link', link);
    if (images) {
      Array.from(images).forEach((file) => formData.append('images', file));
    }
    formData.append('validTill', validTill);
    formData.append('active', JSON.stringify(active));

    onSubmit(formData);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 p-4">
      {/* Background Overlay */}
      <div className="fixed inset-0 bg-black opacity-50"></div>

      {/* Modal */}
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg z-50 w-full max-w-md">
        <h2 className="text-white text-xl mb-4">
          {editingWorkshop ? 'Update Workshop' : 'Add New Workshop'}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-white">Link</label>
            <input
              type="url"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className="w-full p-2 border border-gray-700 rounded"
              placeholder="Enter Workshop Link"
              required
            />
          </div>

          <div className="mb-4">
            <label className="block text-white">Images</label>
            <input
              type="file"
              onChange={(e) => setImages(e.target.files)}
              className="w-full p-2 border border-gray-700 rounded"
              multiple
            />
          </div>

          <div className="mb-4">
            <label className="block text-white">Valid Till</label>
            <input
              type="date"
              value={validTill}
              onChange={(e) => setValidTill(e.target.value)}
              className="w-full p-2 border border-gray-700 rounded"
              required
            />
          </div>

          <div className="mb-4">
            <label className="flex items-center text-white">
              <input
                type="checkbox"
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
                className="mr-2"
              />
              Active
            </label>
          </div>

          <div className="flex justify-between">
            <button type="button" onClick={onCancel} className="bg-red-600 text-white p-2 rounded">
              Cancel
            </button>
            <button type="submit" className="bg-blue-600 text-white p-2 rounded">
              {editingWorkshop ? 'Update Workshop' : 'Add Workshop'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default WorkshopForm;
