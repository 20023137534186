// src/services/courseService.ts

import axios from 'axios';

const API_BASE_URL = 'https://spark-mind.vercel.app/api/courses';

// Get the auth token from localStorage
const getAuthToken = () => localStorage.getItem('token');

// Configure axios instance
const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

// Add a request interceptor to include the Authorization header
apiClient.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// src/services/courseService.ts

export interface SubCategory {
  _id: string;
  name: string;
}

export interface Category {
  _id: string;
  name: string;
}

export interface Course {
  _id: string;
  title: string;
  category: Category; // Changed to Category object
  subCategory?: SubCategory[]; // Changed to an array of SubCategory objects
  description: string;
  price: string;
  image: {
    filename: string;
    url: string;
  };
  enrolledCount: number;
  link: string;
  language?: string[]; // Assuming this might be an array of strings
  duration: number;          // New field for duration in minutes
  noOfLessions: number; 
}



// Fetch all courses
export const getCourses = async (): Promise<Course[]> => {
  const response = await apiClient.get('/');
  return response.data;
};

// Add a new course
export const addCourse = async (courseData: FormData): Promise<Course> => {
  const response = await apiClient.post('/', courseData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

// Update an existing course
export const updateCourse = async (id: string, courseData: FormData): Promise<Course> => {
  const response = await apiClient.put(`/${id}`, courseData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

// Delete a course
export const deleteCourse = async (id: string): Promise<void> => {
  await apiClient.delete(`/${id}`);
};
