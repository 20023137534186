import axios from 'axios';

const API_URL = 'https://spark-mind.vercel.app/api/auth/login'; // Updated to local server

interface LoginResponse {
  token: string;
}

export const login = async (email: string, password: string): Promise<LoginResponse> => {
  try {
    const response = await axios.post<LoginResponse>(API_URL, { email, password });
    return response.data;
  } catch (error) {
    throw new Error('Login failed');
  }
};
