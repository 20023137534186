import axios from 'axios';

const API_URL = 'https://spark-mind.vercel.app/api/category';

export interface Category {
  _id: string;
  name: string;
  active: boolean;
  createDate: string;
  modifiedDate: string;
}

// Fetch categories
export const fetchCategories = async (): Promise<Category[]> => {
  const response = await axios.get(`${API_URL}/getall`);
  return response.data;
};
