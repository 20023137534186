import React, { useState, useEffect } from 'react';
import {
  fetchMainCategories,
  createMainCategory,
  deleteMainCategory,
  MainCategory,
} from '../services/mainCategoryService';
import {
  fetchSubCategories,
  createSubCategory,
  deleteSubCategory,
  SubCategory,
} from '../services/subCategoryService';

const CategoryComponent: React.FC = () => {
  const [mainCategories, setMainCategories] = useState<MainCategory[]>([]);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [selectedMainCategory, setSelectedMainCategory] = useState<string | null>(null);
  const [activeSection, setActiveSection] = useState<'main' | 'sub' | null>(null);
  const [newMainCategory, setNewMainCategory] = useState({ name: '', active: true });
  const [newSubCategory, setNewSubCategory] = useState({ name: '', category: '' });
  const [showMainCategoryForm, setShowMainCategoryForm] = useState<boolean>(false);
  const [showSubCategoryForm, setShowSubCategoryForm] = useState<boolean>(false);

  // Fetch all main categories on component mount
  useEffect(() => {
    if (activeSection === 'main') {
      const getMainCategories = async () => {
        const categories = await fetchMainCategories();
        setMainCategories(categories);
      };

      getMainCategories();
    }
  }, [activeSection]);

  // Fetch subcategories when a main category is selected
  useEffect(() => {
    if (activeSection === 'sub' && selectedMainCategory) {
      const getSubCategories = async () => {
        const subCategories = await fetchSubCategories(selectedMainCategory);
        setSubCategories(subCategories);
      };

      getSubCategories();
    }
  }, [selectedMainCategory, activeSection]);

  // Handle form submit for main category
  const handleMainCategorySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const createdCategory = await createMainCategory(newMainCategory);
      setMainCategories([...mainCategories, createdCategory]);
      setShowMainCategoryForm(false);
      setNewMainCategory({ name: '', active: true });
    } catch (error) {
      console.error('Failed to create main category', error);
    }
  };

  // Handle form submit for subcategory
  const handleSubCategorySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const createdSubCategory = await createSubCategory({
        name: newSubCategory.name,
        category: selectedMainCategory || '',
      });
      setSubCategories([...subCategories, createdSubCategory]);
      setShowSubCategoryForm(false);
      setNewSubCategory({ name: '', category: '' });
    } catch (error) {
      console.error('Failed to create subcategory', error);
    }
  };

  // Delete a main category
  const handleDeleteMainCategory = async (categoryId: string) => {
    if (window.confirm('Are you sure you want to delete this main category?')) {
      try {
        await deleteMainCategory(categoryId);
        setMainCategories(mainCategories.filter((category) => category._id !== categoryId));
        alert('Main category deleted successfully.');
      } catch (error) {
        console.error('Failed to delete main category', error);
        alert('Failed to delete main category.');
      }
    }
  };

  // Delete a subcategory
  const handleDeleteSubCategory = async (subCategoryId: string) => {
    if (window.confirm('Are you sure you want to delete this subcategory?')) {
      try {
        await deleteSubCategory(subCategoryId);
        setSubCategories(subCategories.filter((subCategory) => subCategory._id !== subCategoryId));
        alert('Subcategory deleted successfully.');
      } catch (error) {
        console.error('Failed to delete subcategory', error);
        alert('Failed to delete subcategory.');
      }
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Categories</h1>

      {/* Buttons for Main Category and Subcategory */}
      <div className="flex justify-center gap-6 mb-8">
        <button
          onClick={() => setActiveSection('main')}
          className={`py-2 px-4 rounded ${activeSection === 'main' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Main Category
        </button>
        <button
          onClick={() => setActiveSection('sub')}
          className={`py-2 px-4 rounded ${activeSection === 'sub' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
        >
          Sub Category
        </button>
      </div>

      {/* Main Category Section */}
      {activeSection === 'main' && (
        <div>
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold">Main Categories</h2>
            <button
              onClick={() => setShowMainCategoryForm(true)}
              className="bg-blue-500 text-white py-2 px-4 rounded"
            >
              Add Main Category
            </button>
          </div>

          {showMainCategoryForm && (
            <form onSubmit={handleMainCategorySubmit} className="mt-4">
              <input
                type="text"
                value={newMainCategory.name}
                onChange={(e) => setNewMainCategory({ ...newMainCategory, name: e.target.value })}
                className="p-2 border border-gray-400 rounded w-full"
                placeholder="Main category name"
                required
              />
              <button type="submit" className="mt-4 bg-green-500 text-white py-2 px-4 rounded">
                Create Main Category
              </button>
            </form>
          )}

          <ul>
            {mainCategories.map((category) => (
              <li key={category._id} className="p-2 border-b border-gray-200 flex justify-between items-center">
                {category.name}
                <button
                  onClick={() => handleDeleteMainCategory(category._id)}
                  className="text-red-500 hover:underline"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Sub Category Section */}
      {activeSection === 'sub' && (
        <div>
          <select
            value={selectedMainCategory || ''}
            onChange={(e) => setSelectedMainCategory(e.target.value)}
            className="p-2 border border-gray-400 rounded w-full"
          >
            <option value="">Select a main category</option>
            {mainCategories.map((category) => (
              <option key={category._id} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>

          {selectedMainCategory && (
            <>
              <div className="flex items-center justify-between mt-2">
                <h3 className="text-2xl font-bold">Subcategories</h3>
                <button
                  onClick={() => setShowSubCategoryForm(true)}
                  className="bg-blue-500 text-white py-2 px-4 rounded"
                >
                  Add Subcategory
                </button>
              </div>

              {showSubCategoryForm && (
                <form onSubmit={handleSubCategorySubmit} className="mt-4">
                  <input
                    type="text"
                    value={newSubCategory.name}
                    onChange={(e) => setNewSubCategory({ ...newSubCategory, name: e.target.value })}
                    className="p-2 border border-gray-400 rounded w-full"
                    placeholder="Subcategory name"
                    required
                  />
                  <button type="submit" className="mt-4 bg-green-500 text-white py-2 px-4 rounded">
                    Create Subcategory
                  </button>
                </form>
              )}

              <ul>
                {subCategories.map((subCategory) => (
                  <li key={subCategory._id} className="p-2 border-b border-gray-200 flex justify-between items-center">
                    {subCategory.name}
                    <button
                      onClick={() => handleDeleteSubCategory(subCategory._id)}
                      className="text-red-500 hover:underline"
                    >
                      Delete
                    </button>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CategoryComponent;
