import React, { useState } from 'react';
import { login } from '../services/authService';

const LoginPage: React.FC<{ onLoginSuccess: () => void }> = ({ onLoginSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const data = await login(email, password);
      localStorage.setItem('token', data.token); // Store the JWT token
      onLoginSuccess();
    } catch (err) {
      setError('Invalid email or password');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-900">
      <div className="bg-gray-800 p-8 rounded-lg shadow-md w-full max-w-md text-center">
        <h2 className="text-white text-2xl font-bold mb-6">Admin Log In</h2>
        <form onSubmit={handleLogin}>
          {error && <p className="text-red-500">{error}</p>}
          <div className="mb-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full p-4 bg-black text-white placeholder-gray-500 border border-gray-700 rounded-full focus:outline-none"
              placeholder="Enter An Email"
              required
            />
          </div>
          <div className="mb-6 relative">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-4 bg-black text-white placeholder-gray-500 border border-gray-700 rounded-full focus:outline-none"
              placeholder="Enter A Password"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full bg-yellow-600 text-white p-4 rounded-full hover:bg-yellow-700"
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
