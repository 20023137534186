import axios from 'axios';

const API_URL = 'https://spark-mind.vercel.app/api/category';

export interface MainCategory {
  _id: string;
  name: string;
  active: boolean;
  createDate: string;
  modifiedDate: string;
}

// Fetch all main categories
export const fetchMainCategories = async (): Promise<MainCategory[]> => {
  const response = await axios.get(`${API_URL}/getall`);
  return response.data;
};

// Create a new main category
export const createMainCategory = async (categoryData: { name: string; active: boolean }): Promise<MainCategory> => {
  const response = await axios.post(`${API_URL}/create`, categoryData);
  return response.data;
};

// Delete a main category
export const deleteMainCategory = async (categoryId: string): Promise<void> => {
  await axios.delete(`${API_URL}/${categoryId}`);
};

