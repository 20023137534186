import axios from 'axios';

const API_URL = 'https://spark-mind.vercel.app/api/subcategories';

export interface SubCategory {
  _id: string;
  name: string;
  category: {
    _id: string;
    name: string;
    active: boolean;
    createDate: string;
    modifiedDate: string;
  }[];
}

// Fetch subcategories based on main category
export const fetchSubCategories = async (categoryId: string): Promise<SubCategory[]> => {
  const response = await axios.get(`${API_URL}/get/`, { params: { category: categoryId } });
  return response.data;
};


// Create a new subcategory
export const createSubCategory = async (subCategoryData: { name: string; category: string }): Promise<SubCategory> => {
  const response = await axios.post(`${API_URL}/create`, subCategoryData);
  return response.data;
};

// Delete a subcategory
export const deleteSubCategory = async (subCategoryId: string): Promise<void> => {
  await axios.delete(`${API_URL}/${subCategoryId}`);
};
