import React, { useState, useEffect } from 'react';
import { fetchBanners, createBanner, updateBanner, deleteBanner, Banner } from '../services/bannerService';

const SaleBanner: React.FC = () => {
  const [banners, setBanners] = useState<Banner[]>([]);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [currentBanner, setCurrentBanner] = useState<Banner | null>(null);
  const [formData, setFormData] = useState({
    description: '',
    color: '#ffffff',
    active: true,
    validTill: '',
  });
  const [loading, setLoading] = useState<boolean>(true);

  // Fetch all banners on component mount
  useEffect(() => {
    const getBanners = async () => {
      try {
        const banners = await fetchBanners();
        setBanners(banners);
      } catch (error) {
        console.error('Failed to fetch banners', error);
      } finally {
        setLoading(false);
      }
    };

    getBanners();
  }, []);

  // Open form for adding or editing a banner
  const handleAddOrEditBanner = (banner?: Banner) => {
    setShowForm(true);
    if (banner) {
      setCurrentBanner(banner); // Set current banner to edit
      const validTillDateTime = banner.validTill.split('.')[0]; // Extract date and time
      setFormData({
        description: banner.description,
        color: banner.color,
        active: banner.active,
        validTill: validTillDateTime,
      });
    } else {
      // Reset form for new banner
      setCurrentBanner(null);
      setFormData({
        description: '',
        color: '#ffffff',
        active: true,
        validTill: '',
      });
    }
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (currentBanner) {
        // Update existing banner
        const updatedBanner = await updateBanner(currentBanner._id, formData);
        setBanners(banners.map((b) => (b._id === updatedBanner._id ? updatedBanner : b)));
      } else {
        // Create new banner
        const createdBanner = await createBanner(formData);
        setBanners([...banners, createdBanner]);
      }
      setShowForm(false); // Close the form after submit
    } catch (error) {
      console.error('Failed to save banner', error);
    }
  };

  // Handle banner deletion
  const handleDelete = async (bannerId: string) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this banner?');
    if (confirmDelete) {
      try {
        await deleteBanner(bannerId);
        setBanners(banners.filter((banner) => banner._id !== bannerId));
      } catch (error) {
        console.error('Failed to delete banner', error);
      }
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-6">Sale Banners</h1>

      {/* Button to open form */}
      <button
        onClick={() => handleAddOrEditBanner()}
        className="bg-blue-500 text-white py-2 px-4 rounded mb-6 hover:bg-blue-700 transition"
      >
        New Banner
      </button>

      {/* Modal for adding or editing a banner */}
      {showForm && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Description</label>
                <input
                  type="text"
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Color</label>
                <input
                  type="color"
                  value={formData.color}
                  onChange={(e) => setFormData({ ...formData, color: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Active</label>
                <input
                  type="checkbox"
                  checked={formData.active}
                  onChange={(e) => setFormData({ ...formData, active: e.target.checked })}
                  className="mr-2"
                />
                <span>{formData.active ? 'Active' : 'Inactive'}</span>
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 font-bold mb-2">Valid Till (Date & Time)</label>
                <input
                  type="datetime-local"
                  value={formData.validTill}
                  onChange={(e) => setFormData({ ...formData, validTill: e.target.value })}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={() => setShowForm(false)}
                  className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 transition"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 transition"
                >
                  {currentBanner ? 'Update Banner' : 'Add Banner'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* List of banners */}
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {banners.map((banner) => (
            <div
              key={banner._id}
              className="p-4 border rounded-lg shadow-md"
              style={{ backgroundColor: banner.color }}
            >
              <h3 className="text-xl font-bold mb-2">{banner.description}</h3>
              <p>
                <strong>Status:</strong> {banner.active ? 'Active' : 'Inactive'}
              </p>
              <p>
                <strong>Valid Till:</strong> {new Date(banner.validTill).toLocaleString()}
              </p>
              <p className="text-sm text-gray-600">
                <strong>Created:</strong> {new Date(banner.createdDate).toLocaleDateString()}
              </p>
              <div className="mt-4 flex justify-between">
                <button
                  onClick={() => handleAddOrEditBanner(banner)}
                  className="bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-600 transition"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(banner._id)}
                  className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600 transition"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SaleBanner;
