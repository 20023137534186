import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaUser, FaBook, FaChalkboardTeacher, FaSignOutAlt,FaBriefcase } from 'react-icons/fa'; // Icons

interface NavbarProps {
  onLogout: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    console.log('Logout button clicked');
    onLogout();
    navigate('/login');
  };

  return (
    <nav className="fixed top-0 left-0 h-screen w-44 bg-blue-600 p-4 flex flex-col justify-between">
      {/* Sidebar content */}
      <div>
        <div className="text-white text-2xl font-bold mb-8">
          <Link to="/">SparkAdmin</Link>
        </div>
        <div className="space-y-4">
          <SidebarLink to="/courses" icon={<FaBook />} text="Courses" />
          <SidebarLink to="/workshops" icon={<FaChalkboardTeacher />} text="Workshops" />
          <SidebarLink to="/post-job" icon={<FaBriefcase />} text="Jobs" /> {/* Added Jobs link */}
          <SidebarLink to="/applications" icon={<FaBriefcase />} text="Jobs-Apll" /> {/* Added Jobs link */}
          <SidebarLink to="/saleBanner" icon={<FaBriefcase />} text="SaleBanner" /> {/* Added Jobs link */}
          <SidebarLink to="/Category" icon={<FaBriefcase />} text="Category" /> {/* Added Jobs link */}
        </div>
      </div>

      {/* Logout Section */}
      <div>
        <button
          onClick={handleLogout}
          className="w-full flex items-center text-white hover:text-gray-300 transition-colors p-2"
        >
          <FaSignOutAlt className="mr-2" />
          Logout
        </button>
      </div>
    </nav>
  );
};

interface SidebarLinkProps {
  to: string;
  icon: React.ReactNode;
  text: string;
}

const SidebarLink: React.FC<SidebarLinkProps> = ({ to, icon, text }) => (
  <Link
    to={to}
    className="w-full flex items-center text-white hover:bg-blue-700 transition-colors p-2 rounded"
  >
    <span className="mr-3">{icon}</span>
    {text}
  </Link>
);

export default Navbar;
