import React, { useState, useEffect } from 'react';
import { addJob, fetchJobs, updateJob, deleteJob, Job } from '../services/jobService';

const PostJob: React.FC = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [qualification, setQualification] = useState('');
  const [active, setActive] = useState(true);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [editingJob, setEditingJob] = useState<Job | null>(null); // For editing
  const [isFormVisible, setIsFormVisible] = useState(false); // To toggle the form visibility

  useEffect(() => {
    const getJobs = async () => {
      const jobsData = await fetchJobs();
      setJobs(jobsData);
    };
    getJobs();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const jobData: Job = {
      title,
      description,
      qualification,
      active,
    };

    try {
      if (editingJob) {
        await updateJob(editingJob._id!, jobData);
        alert('Job updated successfully');
      } else {
        await addJob(jobData);
        alert('Job added successfully');
      }

      const jobsData = await fetchJobs();
      setJobs(jobsData);

      setTitle('');
      setDescription('');
      setQualification('');
      setActive(true);
      setEditingJob(null);
      setIsFormVisible(false);
    } catch (error) {
      alert('Failed to submit job');
    }
  };

  const handleEdit = (job: Job) => {
    setEditingJob(job);
    setTitle(job.title);
    setDescription(job.description);
    setQualification(job.qualification);
    setActive(job.active);
    setIsFormVisible(true);
  };

  const handleAddJobClick = () => {
    setEditingJob(null);
    setTitle('');
    setDescription('');
    setQualification('');
    setActive(true);
    setIsFormVisible(true);
  };

  const handleDelete = async (jobId: string) => {
    console.log(`Deleting job with ID: ${jobId}`); // Debug log
  
    if (window.confirm('Are you sure you want to delete this job?')) {
      try {
        await deleteJob(jobId);
        alert('Job deleted successfully');
        const jobsData = await fetchJobs();
        setJobs(jobsData);
      } catch (error) {
        console.error('Failed to delete job:', error); // Debug error
        alert('Failed to delete job');
      }
    }
  };
  

  return (
    <div className="p-6 relative">
      <h1 className="text-2xl font-bold mb-6">Job Management</h1>

      {!isFormVisible && (
        <button
          onClick={handleAddJobClick}
          className="bg-blue-600 text-white p-2 rounded mb-6"
        >
          Add Job
        </button>
      )}

      {isFormVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <form onSubmit={handleSubmit} className="bg-gray-800 p-4 rounded-lg shadow-lg text-white w-96">
            <h2 className="text-white text-xl mb-4">{editingJob ? 'Update Job' : 'Add New Job'}</h2>
            <div className="mb-4">
              <label className="block">Job Title</label>
              <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block">Job Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block">Qualification</label>
              <input
                type="text"
                value={qualification}
                onChange={(e) => setQualification(e.target.value)}
                className="w-full p-2 rounded bg-gray-700 border border-gray-600"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block">Active</label>
              <input
                type="checkbox"
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
              />
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                onClick={() => setIsFormVisible(false)}
                className="bg-red-600 text-white p-2 rounded"
              >
                Cancel
              </button>
              <button type="submit" className="bg-blue-600 text-white p-2 rounded">
                {editingJob ? 'Update Job' : 'Add Job'}
              </button>
            </div>
          </form>
        </div>
      )}

      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">All Jobs</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {jobs.map((job) => (
            <div key={job._id} className="bg-gray-800 text-white p-4 rounded-lg shadow-lg">
              <h3 className="text-lg font-semibold">{job.title}</h3>
              <p>{job.description}</p>
              <p>Qualification: {job.qualification}</p>
              <p>Status: {job.active ? 'Active' : 'Inactive'}</p>
              <p>Posted on: {new Date(job.createdDate!).toLocaleDateString()}</p>
              <div className="flex justify-between mt-2">
                <button
                  onClick={() => handleEdit(job)}
                  className="text-blue-500 hover:underline"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(job._id!)}
                  className="text-red-500 hover:underline"
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PostJob;
