import axios from 'axios';

const API_URL = 'https://spark-mind.vercel.app/api/banners';

export interface Banner {
  _id: string;
  description: string;
  color: string;
  active: boolean;
  validTill: string;
  createdDate: string;
}

// Fetch all banners
export const fetchBanners = async (): Promise<Banner[]> => {
  const response = await axios.get(API_URL);
  return response.data;
};

// Create a new banner
export const createBanner = async (bannerData: Omit<Banner, '_id' | 'createdDate'>): Promise<Banner> => {
  const response = await axios.post(API_URL, bannerData);
  return response.data;
};

// Update an existing banner
export const updateBanner = async (bannerId: string, bannerData: Omit<Banner, '_id' | 'createdDate'>): Promise<Banner> => {
  const response = await axios.put(`${API_URL}/${bannerId}`, bannerData);
  return response.data;
};

// Delete a banner
export const deleteBanner = async (bannerId: string): Promise<void> => {
  await axios.delete(`${API_URL}/${bannerId}`);
};
